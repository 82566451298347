$jost: 'Jost', sans-serif;
$dmSans: 'DM Sans', sans-serif;
$josefinSans: 'Josefin Sans', sans-serif;

$error-text: rgb(122, 12, 46);
$error-background: rgb(255, 231, 217);
$error-icon: rgb(255, 72, 66);

$primary-light: #242c3a;
$primary-main: #1e2530;
$primary-main-hover: rgba(30, 37, 48, 0.15);
$primary-dark: #161c25;
$primary-extra-dark: #11161e;
$primary-darker: $primary-extra-dark;
$primary-darkest: $primary-extra-dark;
$primary-light-background: rgba(66, 204, 18, 0.02);

$color-white: #ffffff;

$primary-button-background: #1e2530;
$primary-button-background-hover: #161c25;
$text-button-hover: rgba(30, 37, 48, 0.03);
$primary-button-shadow: rgba(28, 49, 68, 0.12) 0 4px 8px 0;

$color-grey: rgb(126, 136, 143);
$color-grey-light: rgb(218, 222, 227);
$color-grey-light-line: rgba(218, 222, 227, 0.1);
$color-grey-text: #d9d9d9;
$color-grey-rich-text: rgba(217, 217, 217, 0.2);
$color-grey-rich-text-hover: rgba(217, 217, 217, 0.3);
$color-grey-icon: #131a29;
$color-grey-border: #ededed;
$color-orange: #e9471d;
$color-orange-darker: #ee4216;

$label: rgb(145, 158, 171);
$label-focused: $primary-light;
$label-error: #d32f2f;

$input-border: rgba(145, 158, 171, 0.32);
$input-border-focused: $primary-light;
$input-border-error: $label-error;
$input-error-text: $label-error;

$background-color: #e8e9eb;
$dark-background: #333333;

$separator-color: rgba(227, 87, 36, 0.2);
$input-border-color: rgba(227, 87, 36, 0.5);
$placeholder-color: rgba(227, 87, 36, 0.6);

$bright-color: #ffffff;
$black-color: #000000;

$red-color: #ef233c;
$red-color-darker: #ef233c;
$red-color-lighter: #fdb0b9;
$red-color-brighter: #efc5bd;

$warning-color: #f19a3e;
$warning-color-brighter: #f6c48d;
$warning-color-darker: #98540b;

$sidebar-color: #313638;
$navigation-color: #313638;

$success-color: #21d19f;
$success-color-brighter: #29eab3;
$error-color: #ef233c;
$error-color-transparent: rgba(239, 35, 60, 0.11);
$warning-color: #f3a712;

$light-grey-background: rgba(34, 51, 84, 0.02);
$dark-blue-text: rgba(34, 51, 84, 0.7);
