@import "../../styles/mixins";
@import "../../styles/variables";

.alert {
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: none;
  background-image: none;
  line-height: 1.57143;
  font-size: 0.875rem;
  font-family: $jost;
  font-weight: 400;
  border-radius: 0.5rem;
  display: flex;
  padding: 0.375rem 0.75rem;

  &.danger {
    color: $error-color;
    background-color: $error-background;

    .icon {
      color: $error-color;
    }
  }

  &.warning {
    color: $warning-color;
    background-color: rgb(255, 247, 205);

    .icon {
      color: $warning-color;
    }
  }

  &.info {
    color: rgb(4, 41, 122);
    background-color: rgb(208, 242, 255);

    .icon {
      color: rgb(24, 144, 255);
    }
  }

  &.success {
    color: $success-color;
    background-color:rgb(233, 252, 212);

    .icon {
      color: $success-color;
    }
  }

  .icon {
    margin-right: 0.75rem;
    padding: 0.4375rem 0;
    display: flex;
    font-size: 1.375rem;
    opacity: 0.9;
  }

  .message {
    padding: 0.5rem 0;
  }
}
