@import "../../styles/mixins";
@import "../../styles/variables";

.label {
  height: 1.375rem;
  min-width: 1.375rem;
  line-height: 0;
  border-radius: 0.5rem;
  cursor: default;
  align-items: center;
  white-space: nowrap;
  display: inline-flex;
  justify-content: center;
  padding: 0 0.5rem;
  font-size: 0.75rem;
  font-family: $jost;
  font-weight: 700;

  &.success {
    color: rgb(34, 154, 22);
    background-color: rgba(84, 214, 44, 0.08);
  }

  &.warning {
    color: rgb(255, 193, 7);
    background-color: rgba(255, 193, 7, 0.16);
  }

  &.error {
    color: rgb(183, 33, 54);
    background-color: rgba(255, 72, 66, 0.16);
  }

  &.info {
    color: rgb(12, 83, 183);
    background-color: rgba(24, 144, 255, 0.08);

    a {
      color: rgb(12, 83, 183);
    }
  }

  &.default {
    color: #6B6B6B;
    background-color: rgba(107,107,107,0.08);
  }

  &.action {
    color: #FB62F6;
    background-color: rgba(251,98,246,0.08);
  }

  &.intermediate {
    color: #0D5D56;
    background-color: rgba(13,93,86,0.3);
  }

  &.malfunction {
    color: #5f07e6;
    background-color: rgba(95, 7, 230, 0.3);
  }

  &.diagnostic {
    color: #17abd5;
    background-color: rgba(105, 221, 255, 0.1);
  }

  &.clickable {
    cursor: pointer;
  }
}
