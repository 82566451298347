@import "../../styles/variables";

.button {
  height: 3rem;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: rgb(255, 255, 255);

  &:global(.MuiButton-containedPrimary) {
    background-color: $primary-button-background;
    box-shadow: $primary-button-shadow;
    border-radius: 0.5rem;
    padding: 0.6875rem 2.5rem;
    font-family: $jost;
    text-transform: capitalize;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    height: 3.125rem;

    &:global(.Mui-disabled) {
      box-shadow: none;
      background-color: rgba(145, 158, 171, 0.24);
    }

    &:hover {
      background-color: $primary-button-background-hover;
    }
  }

  &:global(.MuiButton-containedSecondary) {
    background-color: $color-white;
    border: 1px solid $primary-main;
    box-shadow: $primary-button-shadow;
    border-radius: 0.5rem;
    padding: 0.6875rem 2.5rem;
    font-family: $jost;
    text-transform: capitalize;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    height: 3.125rem;
    color: $primary-main;

    &:global(.Mui-disabled) {
      box-shadow: none;
      background-color: rgba(145, 158, 171, 0.24);
    }

    &:hover {
      background-color: $color-grey-light;
    }
  }

  &:global(.MuiButton-textPrimary) {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    background-color: transparent;
    outline: 0;
    border: 0;
    margin: 0;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    font-weight: 700;
    line-height: 1.71429;
    font-size: 0.875rem;
    text-transform: capitalize;
    font-family: $jost;
    min-width: 4rem;
    padding: 0.375rem 0.5rem;
    border-radius: 0.5rem;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    color: $primary-button-background;
  }

  &.saveButton {
    height: auto;
    padding: 0.375rem 3rem;
  }

  &.disabled {
    cursor: not-allowed;
  }

  &.textButton {
    opacity: 0.8;
    height: auto;
  }

  &.isActive {
    opacity: 1;
  }

  &.colorRed {
    background-color: $red-color-darker;

    &:hover {
      background-color: $red-color;
    }
  }

  &.onlyIcon {
    padding-left: 1rem;
    padding-right: 1rem;
    min-width: 2.5rem;

    :global(.MuiButton-startIcon) {
      margin-right: 0;
      margin-left: 0;
    }
  }

  .loadingIcon {
    margin-right: 0.25rem;
  }
}
