@import "../../styles/variables";
@import "../../styles/mixins";

.staticDateContainer {
  display: flex;

  :global(.MuiPickersCalendarHeader-label) {
    font-family: $dmSans;
    font-weight: 400;
    color: $primary-darkest;
    font-size: 1.375rem;
    text-transform: capitalize;
  }

  :global(.MuiPickerStaticWrapper-content) {
    background-color: transparent;
  }

  :global(.MuiPickersCalendarHeader-root) {
    margin-bottom: 2.5rem;
  }

  :global(.MuiSvgIcon-root) {
    color: $primary-darkest;
  }

  :global(.MuiDayPicker-weekDayLabel) {
    font-family: $dmSans;
    font-weight: 400;
    font-size: 1.25rem;
    color: $primary-darkest;
    width: 3.5rem;
  }

  :global(.MuiPickersDay-root) {
    font-family: $dmSans;
    font-weight: 400;
    font-size: 1.125rem;
    color: $primary-darkest;
    width: 3.5rem;
    border-radius: 0.5rem;
    background-color: transparent;
  }

  :global(.MuiPickersDay-root.Mui-selected) {
    background-color: $primary-main !important;
    color: $color-white;
  }

  :global(.PrivatePickersYear-yearButton.Mui-selected) {
    background-color: $primary-main !important;
    color: $color-white;
  }

  :global(.MuiCalendarOrClockPicker-root) {
    > div {
      width: 100%;
      max-height: none;
    }
  }

  :global(.MuiCalendarPicker-root) {
    width: 100%;
    max-height: none;
  }
}

.textField {
  width: 100%;

  :global(.MuiAutocomplete-inputRoot) {
    padding-right: 0.5625rem !important;
  }

  :global(.MuiAutocomplete-root) {
    width: 100%;
  }

  :global(.MuiInputBase-root) {
    background-color: transparent;
  }

  :global(.MuiInputBase-root:hover) {
    border-radius: 0.5rem;
  }

  &.currencyInput {
    p {
      margin: 0.0625rem 0 0;
      line-height: 1.5;
      font-family: $jost;
      font-weight: 400;
      color: rgb(99, 115, 129);

      &:global(.Mui-error) {
        color: $input-error-text;
        margin: 0.5rem 0.875rem 0;
        font-size: 0.75rem;
        line-height: 1.66;
      }
    }
  }

  &.timeInput {
    :global(.MuiFormHelperText-sizeMedium) {
      position: absolute;
      top: 3.125rem;
    }
  }

  label {
    font-family: $jost;
    color: $label;
    font-size: 0.875rem;
    line-height: 1.4374em;
    font-weight: 400;
    padding: 0;
    display: block;
    transform-origin: left top;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(133% - 1.5rem);
    position: absolute;

    &:global(.Mui-focused) {
      color: $label-focused;
    }

    &:global(.Mui-error) {
      color: $label-error;
    }
  }

  > div {
    border-radius: 0.5rem;
    font-size: 0.875rem;
    font-family: $jost;
    font-weight: 400;
    line-height: 1.4375;

    input {
      font-family: $jost;
    }

    fieldset {
      border-color: $input-border;

      legend {
        width: auto;
      }
    }

    &:global(.Mui-focused) {
      fieldset {
        border-color: $input-border-focused !important;
      }
    }

    &:global(.Mui-error) {
      fieldset {
        border-color: $input-border-error !important;
      }
    }
  }

  p {
    font-family: $jost;
    margin: 0.5rem 0.875rem 0;

    &:global(.Mui-error) {
      color: $input-error-text;
    }
  }
}

.switcherContainer {
  margin-top: 0.625rem;

  :global(.MuiSwitch-switchBase) {
    color: $red-color;
  }

  :global(.Mui-checked) {
    color: $success-color !important;
  }

  :global(.Mui-checked+.MuiSwitch-track) {
    background-color: $success-color !important;
  }

  :global(.MuiSwitch-track) {
    background-color: $red-color;
  }

  :global(.MuiFormControlLabel-label) {
    line-height: 1.57143;
    font-size: 0.875rem;
    font-family: $jost;
    font-weight: 400;
  }
}

.checkboxContainer {
  width: 100%;
  margin-left: 0 !important;
  margin-right: 0 !important;

  :global(.MuiSvgIcon-root) {
    width: 1rem;
    height: 1rem;
  }

  :global(.MuiFormControlLabel-label) {
    font-family: $jost;
    font-weight: 300;
    color: $primary-dark;
    font-size: 1rem;
  }

  :global(.Mui-checked) {
    color: $primary-dark !important;
  }

  &:hover {
    background-color: $primary-light;
  }
}

.editorContainer {
  &.error {
    div:first-of-type {
      border-color: $red-color;
    }
  }

  .editorError {
    font-family: $jost;
    font-weight: 400;
    font-size: 0.75rem;
    color: $red-color;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    text-align: left;
    margin: 0.5rem 0.875rem 0;
  }
}

.helperIcon {
  margin-left: -8px;
  cursor: pointer;
}

.helperPopover {
  :global(.MuiPaper-root) {
    background: transparent;
    border-radius: 0.5rem;
  }

  .popoverContent {
    background-color: $color-white;
    color: $primary-main;
    background-image: none;
    overflow: hidden auto;
    min-height: 1rem;
    max-height: calc(100% - 2rem);
    outline: 0;
    max-width: 100%;
    opacity: 1;
    transform: none;
    transition: opacity 230ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 153ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    padding: 0.75rem 0.75rem;
  }
}

.helperLabel {
  width: 100%;
  margin-top: 0.75rem;
  height: auto !important;
  text-align: left;
  justify-content: flex-start !important;
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
  white-space: unset !important;
  line-height: 1.5rem !important;

  .helperLabelIcon {
    font-size: 1rem;
    margin-right: 0.25rem;
  }
}

.dateChangeButton {
  padding: 0 !important;
  border-color: $input-border !important;
}

.buttonSelectContainer {
  gap: 0.5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .button {
    font-size: 0.75rem;
    line-height: 1rem;
  }
}

.timeWithButtonsWrapper {
  position: relative;

  .timePopoverContent {
    background-color: $color-white;
    color: $primary-main;
    background-image: none;
    overflow: hidden auto;
    min-height: 1rem;
    outline: 0;
    max-width: 100%;
    opacity: 1;
    transform: none;
    transition: opacity 230ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 153ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    padding: 0.75rem 0.75rem;
    position: absolute;
    border-radius: 8px;
    z-index: 9;
    margin-top: -120px;
    box-shadow: rgba(145, 158, 171, 0.24) 0 0 2px 0, rgba(145, 158, 171, 0.24) 0 12px 24px 0;

    .timeWithButtonsContainer {
      display: flex;
      gap: 4px;

      .timeControlContainer {
        display: flex;
        flex-direction: column;
        width: 40px;
        gap: 4px;

        .controlButton {
          height: 20px;
          width: auto;
          padding: 0;
          min-width: 0;
        }

        .timeControlInput {
          height: 30px;

          div {
            height: 30px;

            input {
              font-size: 12px;
              padding-left: 8px;
              padding-right: 8px;
              text-align: center;
            }
          }
        }
      }
    }
  }
}
