@import '../../styles/variables';
@import '../../styles/mixins';

.container {
  display: flex;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.rightSection {
  overflow: auto;
  display: flex;
  flex-direction: column;
  width: 100%;

  @include bp(m) {
    padding: 0;
  }

  &.noScroll {
    overflow: hidden;
  }

  .content {
    padding: 0 1.5rem 2.5rem 1.5rem;
    margin-top: 1rem;

    @include bp(m) {
      margin-top: 5rem;
      padding: 0 0.5rem 2.5rem 1.5rem;
    }

    @include bp(l) {
      padding: 0 0 2.5rem 0;
    }
  }
}

.mobileDrawer {
  background-color: rgba(255, 255, 255, 1);
  z-index: 8;
  box-shadow: rgba(34, 51, 84, 0.2) 0 0.125rem 0.5rem -0.1875rem,
    rgba(34, 51, 84, 0.1) 0 0.3125rem 1.375rem -0.25rem;
  position: absolute;
  top: 80px;
  height: calc(100% - 80px);
  width: 100%;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  .mobileDrawerInner {
    overflow: auto;
    height: 100%;

    .navigationGroup {
      width: 100%;
      margin-top: 1.25rem;

      .groupName {
        box-sizing: border-box;
        list-style: none;
        font-family: $jost;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 0.75rem;
        color: $black-color;
        line-height: 1.4;
        padding: 0 1.406rem 0.5rem;
      }

      .navigationItem {
        display: flex;
        color: $color-grey;
        width: 100%;
        justify-content: flex-start;
        padding: 0.875rem 1.5rem;
        align-items: center;
        position: relative;
        box-sizing: border-box;
        outline: 0;
        border: 0;
        cursor: pointer;
        user-select: none;
        vertical-align: middle;
        appearance: none;
        text-decoration: none;
        font-family: $jost;
        font-size: 0.875rem;
        line-height: 1.75;
        min-width: 4rem;
        border-radius: 0.5rem;
        transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
          box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
          border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
          color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        font-weight: 500;
        text-transform: none;
        margin: 0 0 0.25rem;

        &:hover {
          background-color: rgba(25, 118, 210, 0.06);
        }

        &.activeSubItem {
          background-color: rgba(25, 118, 210, 0.12);
          color: $primary-main;
          font-weight: 600;
        }

        .navigationIcon {
          margin-right: 0.625rem;
        }
      }
    }
  }
}

.locationSelectModal {
  :global(.MuiDialog-paperScrollPaper) {
    max-width: 37.5rem !important;
  }
}

.publicContainer {
  min-height: 100vh;
}
