@import "../../styles/variables";
@import "../../styles/mixins";

.currentAccountContainer {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
  background-color: rgba(145, 158, 171, 0.12);
  border-radius: 0.5rem;
  height: 4.75rem;
  display: flex;
  align-items: center;
  padding: 1rem 1.25rem;
  cursor: pointer;

  .content {
    display: flex;
    flex-direction: column;
    margin-left: 1rem;
    margin-right: 1rem;

    .title {
      font-family: $jost;
      font-weight: 600;
      font-size: 0.875rem;
    }

    .subTitle {
      font-family: $jost;
      font-weight: 500;
      font-size: 0.75rem;
      color: $primary-light;
      margin-top: 0.25rem;
    }

    .companyName {
      font-family: $jost;
      color: $primary-main;
      font-size: 1rem;
    }
  }
}
